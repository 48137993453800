import { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

import t from 'js_utils/translations';
import { resetPassword, validateResetToken } from 'api/reset.api';
import { logErrorInfo } from 'RollbarErrorTracking';

import globalStyles from 'assets/css/global_styles.css';
import styles from 'assets/css/reset_form.css';
import ValidateResetToken from './ValidateResetToken';

const getSpecialCharacters = (password) => {
  const specialCharsString = password;
  return specialCharsString.replace(/[a-zA-Z0-9 ]/g, '');
};
const checkLength = (password) => {
  return password.length >= 12 && password.length <= 30;
};

const checkDigits = (password) => {
  return /\d/.test(password);
};

const checkSpecialChars = (password) => {
  const specialCharsString = getSpecialCharacters(password);
  const valid = /[!#$%^&*]/;
  return valid.test(specialCharsString);
};

const checkForNoInvalidSpecialChars = (password) => {
  const specialCharsString = getSpecialCharacters(password);
  const invalidCharRegex = /[^!#$%^&*]/g;
  return !invalidCharRegex.test(specialCharsString);
};

const checkUpperCase = (password) => {
  return /[A-Z]/.test(password);
};

const checkLowerCase = (password) => {
  return /[a-z]/.test(password);
};

const ResetPasswordForm = (props) => {
  const { newUser } = props;
  const [lengthGood, setLengthGood] = useState(undefined);
  const [digitsGood, setDigitsGood] = useState(undefined);
  const [specialCharsGood, setSpecialCharsGood] = useState(undefined);
  const [hasNoInvalidSpecialChars, setHasNoInvalidSpecialChars] =
    useState(undefined);
  const [lowerCaseGood, setLowerCaseGood] = useState(undefined);
  const [upperCaseGood, setUpperCaseGood] = useState(undefined);
  const [passwordsMatch, setPasswordsMatch] = useState(undefined);
  const [resetError, setResetError] = useState('');
  const [disabledForm, setDisabledForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [resetting, setResetting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [resetTokenValidation, setResetTokenValidation] = useState({});

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('reset_password');

  useEffect(() => {
    if (_.last(props.location.pathname.split('/')) === 'reset') {
      props.history.push('/');
    }
  });

  useEffect(() => {
    const checkToken = async () => {
      try {
        setIsLoading(true);
        const response = await validateResetToken(token);
        setResetTokenValidation(response);
      } catch (error) {
        logErrorInfo(error);
      } finally {
        setIsLoading(false);
      }
    };
    checkToken();
  }, [token]);

  const checkPassword = (password) => {
    const lengthCheck = checkLength(password);
    const digitCheck = checkDigits(password);
    const specialCharsCheck = checkSpecialChars(password);
    const uppercaseCheck = checkUpperCase(password);
    const lowercaseCheck = checkLowerCase(password);
    const invalidCharsCheck = checkForNoInvalidSpecialChars(password);
    setLengthGood(lengthCheck);
    setDigitsGood(digitCheck);
    setSpecialCharsGood(specialCharsCheck);
    setHasNoInvalidSpecialChars(invalidCharsCheck);
    setUpperCaseGood(uppercaseCheck);
    setLowerCaseGood(lowercaseCheck);
    setResetError('');
    const isValid =
      lengthCheck &&
      digitCheck &&
      specialCharsCheck &&
      uppercaseCheck &&
      lowercaseCheck &&
      invalidCharsCheck;
    if (!isValid) setDisableSubmit(true);
    else setDisableSubmit(false);
  };

  const isDefined = (data) => {
    return typeof data !== 'undefined';
  };

  const checkPasswordsMatch = (userPassword, passwordConfirmation) => {
    return userPassword === passwordConfirmation
      ? setPasswordsMatch(true)
      : setPasswordsMatch(false);
  };

  const lengthGoodClass = lengthGood ? styles.green : styles.white;
  const digitsGoodClass = digitsGood ? styles.green : styles.white;
  const specialCharsGoodClass = specialCharsGood ? styles.green : styles.white;
  const noInvalidSpecialCharsClass = hasNoInvalidSpecialChars
    ? styles.green
    : styles.white;
  const upperCaseGoodClass = upperCaseGood ? styles.green : styles.white;
  const lowerCaseGoodClass = lowerCaseGood ? styles.green : styles.white;
  const passwordsMatchClass = !passwordsMatch ? styles.white : styles.green;
  const passwordsMatchText = !passwordsMatch
    ? t('login.passwordsDontMatch')
    : t('login.passwordMatch');

  const { register, getValues, setValue, handleSubmit } = useForm();

  const onSubmit = (data) => {
    resetPasswords(data);

    if (
      data.userPassword.length === 0 &&
      data.passwordConfirmation.length === 0
    ) {
      setLengthGood(false);
      setDigitsGood(false);
      setSpecialCharsGood(false);
      setHasNoInvalidSpecialChars(false);
      setUpperCaseGood(false);
      setLowerCaseGood(false);
      setResetError('');
    } else {
      checkPasswordsMatch(data.userPassword, data.passwordConfirmation);
    }
  };

  const getIcon = (name) => {
    if (isDefined(name) && name)
      return <i className="fa fa-check" aria-hidden="true" />;
    return <i className="fa fa-times" aria-hidden="true" />;
  };

  const handleChange = (e) => {
    if (e.target.name === 'userPassword') {
      setValue('userPassword', e.target.value);
      if (getValues().userPassword.length === 1) {
        setLengthGood(false);
        setDigitsGood(false);
        setSpecialCharsGood(false);
        setHasNoInvalidSpecialChars(false);
        setUpperCaseGood(false);
        setLowerCaseGood(false);
        setResetError('');
      }
      checkPassword(e.target.value);
      if (getValues().passwordConfirmation.length) {
        checkPasswordsMatch(
          getValues().userPassword,
          getValues().passwordConfirmation
        );
      }
    } else {
      setValue('passwordConfirmation', e.target.value);
      setResetError('');
      const values = getValues();
      checkPasswordsMatch(values.userPassword, values.passwordConfirmation);
    }
  };

  const clearForm = () => {
    setPasswordsMatch(undefined);
    setLengthGood(undefined);
    setDigitsGood(undefined);
    setSpecialCharsGood(undefined);
    setHasNoInvalidSpecialChars(undefined);
    setUpperCaseGood(undefined);
    setLowerCaseGood(undefined);
    setResetError('');
    setValue('userPassword', '');
    setValue('passwordConfirmation', '');
    setResetting(false);
  };

  const resetPasswords = (data) => {
    if (!data) return;
    setResetting(true);
    const payload = { token: token, password: data.userPassword };
    resetPassword(payload)
      .then((response) => {
        props.history.push('/reset-confirmation', {
          passwordReset: true,
        });
      })
      .catch((error) => {
        logErrorInfo(error);
        if (
          error.response.data.message === 'Reset link has expired or is invalid'
        ) {
          clearForm();
          setResetError(error.response.data.message);
          setDisabledForm(true);
        } else {
          clearForm();
          setResetError(error.response.data.message || '');
        }
      });
  };

  if (isLoading)
    return (
      <div className={globalStyles.rectangle}>
        <CircularProgress />
      </div>
    );

  if (!resetTokenValidation?.isValid)
    return <ValidateResetToken resetTokenValidation={resetTokenValidation} />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {resetError.length ? (
        <div className={globalStyles.error}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          <span
            className={
              resetError.length < 60
                ? globalStyles.form_error
                : globalStyles.form_error_small
            }
          >
            {resetError}
          </span>
        </div>
      ) : (
        <div className={globalStyles.no_error}></div>
      )}
      <div
        className={
          resetError.length
            ? globalStyles.rectangle_err
            : globalStyles.rectangle
        }
      >
        {newUser ? (
          <div>
            <div className={globalStyles.header_txt}>{t('login.welcome')}</div>
            <div className={globalStyles.subtitle}>
              {t('login.passwordSetUpText')}
            </div>
          </div>
        ) : (
          <div className={globalStyles.header_txt}>
            {t('login.resetPassword')}
          </div>
        )}
        <input
          autoFocus
          className={
            disabledForm
              ? `${globalStyles.user_cred} ${globalStyles.disabled_input}`
              : globalStyles.user_cred
          }
          type="password"
          placeholder={t('login.newPassword')}
          onChange={handleChange}
          name="userPassword"
          disabled={disabledForm}
          ref={register}
        />
        <div className={styles.requirements_div}>
          <ul className={styles.requirements_list}>
            <li className={styles.password_requirements}>
              Password Requirements
            </li>
            <li className={lengthGoodClass}>
              <div className={styles.sym_container}>{getIcon(lengthGood)}</div>
              {t('login.length1230')}
            </li>
            <li className={upperCaseGoodClass}>
              <div className={styles.sym_container}>
                {getIcon(upperCaseGood)}
              </div>
              Contains an uppercase letter (A–Z)
            </li>
            <li className={lowerCaseGoodClass}>
              <div className={styles.sym_container}>
                {getIcon(lowerCaseGood)}
              </div>
              Contains a lowercase letter (a–z)
            </li>
            <li className={digitsGoodClass}>
              <div className={styles.sym_container}>{getIcon(digitsGood)}</div>
              {t('login.password1Number')}
            </li>
            <li className={specialCharsGoodClass}>
              <div className={styles.sym_container}>
                {getIcon(specialCharsGood)}
              </div>
              {t('login.passwordSpecial')}
            </li>
            <li className={noInvalidSpecialCharsClass}>
              <div className={styles.sym_container}>
                {getIcon(hasNoInvalidSpecialChars)}
              </div>
              {t('login.passwordSpecialCharsLimited')}
            </li>
          </ul>
        </div>
        <input
          className={
            disabledForm
              ? `${globalStyles.user_cred} ${globalStyles.disabled_input}`
              : globalStyles.user_cred
          }
          type="password"
          placeholder={t('login.confirmPassword')}
          onChange={handleChange}
          name="passwordConfirmation"
          disabled={disabledForm}
          ref={register}
        />
        {isDefined(passwordsMatch) ? (
          <div>
            <ul className={styles.requirements_list}>
              <li className={passwordsMatchClass}>
                <div className={styles.sym_container}>
                  {getIcon(passwordsMatch)}
                </div>
                {passwordsMatchText}
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <ul className={styles.requirements_list}>
              <li>&nbsp;</li>
            </ul>
          </div>
        )}
        <div className={globalStyles.btn_container}>
          <input
            id="reset-button"
            type="submit"
            className={
              resetting || disabledForm || disableSubmit || !passwordsMatch
                ? `${globalStyles.btn_main} ${globalStyles.disabled_btn_main} ${styles.reset_btn}`
                : `${globalStyles.btn_main} ${globalStyles.reset_btn}`
            }
            disabled={
              resetting || disabledForm || disableSubmit || !passwordsMatch
            }
            value={
              resetting
                ? t('login.updatingPassword') + '...'
                : t('login.updatePassword')
            }
          />
        </div>
      </div>
    </form>
  );
};

export default withRouter(ResetPasswordForm);
